import { GetServerSideProps } from 'next'
import getConfig from 'next/config'
import React from 'react'

import { isDashboardActive, isEventsPageActive } from 'lib/addons'
import { MarketplaceCategory } from 'lib/constants'
import { routes } from 'lib/routes'

import { UnauthorizedLayout } from 'layout/UnauthorizedLayout'
import { ErrorPageNotFound } from 'scenes/Errors/ErrorPageNotFound'
import { ErrorSubdomainNotFound } from 'scenes/Errors/ErrorSubdomainNotFound'
import { ExternalProviderWebsitePage } from 'scenes/MyPage/WebsiteBuilder/ExternalProviderWebsitePage'
import { fetchActiveBookableServices } from 'services/api/BookableServices'
import { fetchMarketplaceCategories } from 'services/api/Marketplace'
import { listUpcomingProviderEvents, UpcomingEvent } from 'services/api/Meetings'
import { fetchExternal } from 'services/api/ProviderWebsite'
import { fetchMarketplaceProviders, ProviderUser } from 'services/api/Users'
import {
  BookableServicesProps,
  createPage,
  isApp,
  isAuthenticated,
  isCustomer,
  ProviderWebsiteProps,
  ServerUserProps,
  SubdomainStatusProps,
} from 'services/auth/ssp'

import MarketplacePageWithLayout from '../scenes/Marketplace/MarketplacePageWithLayout'

const {
  publicRuntimeConfig: {
    featureFlags: { isMarketplaceEnabled, isMarketplaceMainPage, areEventsOn, isEventsDefaultPage },
    DOMAIN,
  },
} = getConfig()

type MarketplaceProps = {
  marketplaceCategories?: MarketplaceCategory[]
  providers?: ProviderUser[]
  marketplaceTranslations?: {
    [key: string]: {
      [key: string]: string
    }
  }
}

type EventsProps = {
  events?: UpcomingEvent[]
}

type PageProps = ServerUserProps &
  SubdomainStatusProps &
  ProviderWebsiteProps &
  BookableServicesProps &
  MarketplaceProps &
  EventsProps

export const getServerSideProps: GetServerSideProps<PageProps> = async (ctx) => {
  return await createPage<
    SubdomainStatusProps & ProviderWebsiteProps & BookableServicesProps & MarketplaceProps
  >(ctx, {
    loadUser: true,
    loadSubdomainStatus: true,
    redirects: { onboarding: false, subscription: true },
    userConditions: async (props) => {
      // Redirect to provider booking page if website is not live (Keeps custom domain)
      if (!isApp(props)) {
        if (props.subdomainStatus && !props.subdomainStatus.status.is_live) {
          return { redirect: { destination: routes.booking.index, permanent: false } }
        }
        return // Continue rendering provider website
      }

      // Redirect to login when we're at PLATFORM_DEFAULT_SUBDOMAIN subdomain
      if (!isAuthenticated(props)) {
        return {
          redirect: { destination: `${routes.domain}${routes.login.index}`, permanent: false },
        }
      }

      // Customer
      if (isCustomer(props)) {
        return {
          redirect: { destination: `${routes.domain}${routes.signup.index}`, permanent: false },
        }
      }

      // Provider
      if (isEventsDefaultPage && isEventsPageActive(props)) {
        return {
          redirect: {
            destination: `${routes.domain}${routes.events.upcoming}`,
            permanent: false,
          },
        }
      }
      if (isDashboardActive(props)) {
        return {
          redirect: { destination: `${routes.domain}${routes.home}`, permanent: false },
        }
      }
      return {
        redirect: {
          destination: `${routes.domain}${routes.calendar.index}`,
          permanent: false,
        },
      }
    },
    loadData: async (props) => {
      if (props.subdomainStatus?.status.is_live) {
        try {
          const providerWebsite = await fetchExternal(props.subdomainStatus.status.user_id)
          console.debug('providerWebsite', providerWebsite)

          // Fetch bookable services only if primary bank is available
          if (
            !providerWebsite ||
            !providerWebsite['primary_bank'] ||
            !JSON.parse(providerWebsite['primary_bank'])
            // TODO: Check if we may include `bank.include_bookable_services: false` to the condition
          ) {
            return { providerWebsite }
          }

          // Fetch bookable services
          const bookableServices = await fetchActiveBookableServices(
            props.subdomainStatus.status.user_id,
          )
          console.debug('bookableServices', bookableServices)
          if (areEventsOn) {
            const events = await listUpcomingProviderEvents(props.subdomainStatus.status.user_id)
            return { providerWebsite, bookableServices, events }
          }
          return { providerWebsite, bookableServices }
        } catch (error) {
          if (error instanceof Error) {
            console.error('Provider website fetch error', error.message)
          }
        }
      }
      if (isMarketplaceEnabled && isMarketplaceMainPage && props.host === DOMAIN) {
        const providers = await fetchMarketplaceProviders()
        const marketplaceCategories = await fetchMarketplaceCategories()
        const translations = await import(
          '../../env_files/marketplace_whitelabel_translations.json'
        )
        return {
          marketplaceCategories,
          providers,
          marketplaceTranslations: translations.marketplace,
        }
      }
      return {}
    },
  })
}

export default function Page(props: PageProps) {
  if (
    isMarketplaceEnabled &&
    isMarketplaceMainPage &&
    props.host === DOMAIN &&
    props.marketplaceTranslations
  ) {
    return (
      <MarketplacePageWithLayout
        translations={props.marketplaceTranslations}
        currentUrl={props.currentUrl}
        marketplaceCategories={props.marketplaceCategories}
        providers={props.providers}
      />
    )
  }

  // Provider live website
  if (props.subdomainStatus?.status.is_live && props.providerWebsite) {
    return (
      <ExternalProviderWebsitePage
        currentUrl={props.currentUrl}
        providerId={props.subdomainStatus.status.user_id}
        subdomain={props.subdomainStatus.status.subdomain}
        websiteConfig={props.providerWebsite}
        bookableServices={props.bookableServices}
        events={props.events}
      />
    )
  }

  // Subdomain not found
  if (!isApp(props) && !props.subdomainStatus) {
    return <ErrorSubdomainNotFound {...props} currentUrl={props.currentUrl} />
  }

  return (
    <UnauthorizedLayout>
      <ErrorPageNotFound />
    </UnauthorizedLayout>
  )
}
