import i18next from 'i18next'
import getConfig from 'next/config'
import Head from 'next/head'
import React, { useMemo } from 'react'

import { getPlanById } from 'config/usePlanPrices'
import { defaultLogo, findTheme } from 'lib/constants'
import { ogImageFromWebsite, shortSlogan } from 'lib/meta'

import Spinner from 'components/Spinner'
import { UpcomingEvent } from 'services/api/Meetings'
import {
  Bank,
  BankName,
  BookableServices,
  EncodedProviderWebsite,
  ExternalProviderWebsite,
  PersistedEncodedProviderWebsite,
} from 'services/auth/ssp'

const {
  publicRuntimeConfig: { PLATFORM_NAME },
} = getConfig()

type Props = {
  currentUrl: string
  providerId: string
  subdomain: string
  websiteConfig:
    | EncodedProviderWebsite
    | ExternalProviderWebsite
    | PersistedEncodedProviderWebsite
    | null
  bank?: BankName
  bookableServices?: BookableServices
  events?: UpcomingEvent[]
}
export const ExternalProviderWebsitePage = ({
  currentUrl,
  providerId,
  subdomain,
  websiteConfig = null,
  bank = 'primary_bank',
  bookableServices,
  events,
}: Props) => {
  const data = useMemo<Bank>(() => {
    try {
      if (websiteConfig && bank in websiteConfig) {
        return JSON.parse(websiteConfig[bank])
      }
    } catch (error) {
      console.error(error)
    }
  }, [websiteConfig])

  const providerSubscriptionPlan = useMemo(() => {
    if (
      websiteConfig &&
      'provider_subscription_plan_id' in websiteConfig && // type ExternalProviderWebsite
      websiteConfig?.provider_subscription_plan_id
    ) {
      return getPlanById(websiteConfig.provider_subscription_plan_id)
    }
    return undefined
  }, [websiteConfig])

  if (!data) {
    return <Spinner fill />
  }

  const theme = findTheme(data.theme)
  if (!theme) {
    return <Spinner fill />
  }
  const Theme = theme.Component

  const palette = theme.palettes[data.palette]
  const t = i18next.getFixedT(data.lang || 'en')

  const title = data?.title ?? t('website-builder.builder-form.home.main-title')
  const metaTitle = `${title} | ${PLATFORM_NAME}`
  const subtitle = data?.subtitle ?? t('website-builder.builder-form.home.subtitle')
  const description = `${subtitle} - ${shortSlogan}`
  const ogImage = ogImageFromWebsite(data)

  return (
    <>
      <Head>
        <title>{metaTitle}</title>
        <meta key="og:title" property="og:title" content={metaTitle} />
        <meta key="description" name="description" content={description} />
        <meta key="og:description" property="og:description" content={description} />
        <link rel="canonical" href={currentUrl} />
        <meta key="og:image" property="og:image" content={ogImage} />
        <meta key="og:image:width" property="og:image:width" content="1080" />
        <meta key="og:image:height" property="og:image:height" content="1080" />
      </Head>
      <Theme
        t={t}
        subdomain={subdomain}
        lang={data.lang}
        currency={data.currency}
        font={data.font}
        // @ts-expect-error don't know how to type that atm
        palette={palette}
        homePageImage={data.homePageImage ?? undefined}
        homeImagePosition={data.homeImagePosition}
        logo={data.logo ?? defaultLogo}
        title={data.title}
        subtitle={data.subtitle}
        aboutTitle={data.aboutTitle}
        aboutDescription={data.aboutDescription}
        aboutImage={data.aboutImage ?? undefined}
        aboutImagePosition={data.aboutImagePosition}
        bookableServices={data.include_bookable_services ? bookableServices : undefined}
        providerId={providerId}
        shouldHaveBanner={!providerSubscriptionPlan?.policyList.hide_banner}
        events={events}
      />
    </>
  )
}
