import { Box, Button, Heading } from 'grommet'
import getConfig from 'next/config'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { routes } from 'lib/routes'

import PageNotFoundIllustration from 'components/icons/PageNotFoundIllustration'
import { colors } from 'layout/themeColors/colors'

const {
  publicRuntimeConfig: { WEBAPP_URL },
} = getConfig()

export const ErrorPageNotFound = () => {
  const { t } = useTranslation()

  return (
    <Box background={colors.white} fill align="center" justify="between">
      <Box margin="180px" align="center">
        <PageNotFoundIllustration />
        <Heading textAlign="center" level="3" style={{ whiteSpace: 'pre-wrap' }}>
          {t('page-not-found')}
        </Heading>
        <a href={WEBAPP_URL ?? routes.root} style={{ textDecoration: 'none' }}>
          <Button primary size="large" label={t('go-back-to-dashboard')} />
        </a>
      </Box>
    </Box>
  )
}
